import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import { useViewport } from '../hooks/useViewport';
import AboutImg from '../images/about.png';

const Body = styled.div`
  display: grid;
  grid-template-columns: 23.6vw 76.4vw;
  grid-template-rows: 100vh 40vh;
  padding-top: 100px;
`;

const Title = styled.div`
  font-family: 'Chapaza';
  font-size: 4.5vw;
  line-height: 70px;
  width: 32.5vw;
  margin-left: 14.9vw;
  margin-top: 40px;
  z-index: 99;
  letter-spacing: 2px;
`;

const ImageContainer = styled.div`
  grid-column: 2;
  background-color: #f6f4f0;
  display: grid;

  img {
    max-width: 80%;
    max-height: 80%;
    justify-self: center;
    align-self: center;
  }
`;

const Text = styled.div`
  grid-column: 2;
  grid-row: 2;
  color: #212028;
  margin-left: 160px;
  margin-top: 88px;

  @media (max-width: 1360px) {
    margin-top: 20px;
  }

  .title {
    font-family: 'Chapaza';
    font-style: italic;
    font-size: 28px;
  }

  .text {
    color: #212028;
    font-family: Lato;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 24px;
    max-width: 786px;
  }
`;

const MobileContainer = styled.div`
  background-color: #f6f4f0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 90%;
    max-height: 90%;
    margin: 10%;
  }

  .text {
    color: #212028;
    font-family: Lato;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 24px;
    max-width: 786px;
    margin: 10%;
  }
`;

const About = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img src={AboutImg} />
        <div className="text">
          The Armstrong Project is a series of new initiatives led by Wil Armstrong, to preserve the historical record of his father, Senator Bill Armstrong, and especially to build on his important work for faith, family, and freedom. Preserving history is important, especially in an era when so many Americans have forgotten our founding principles. But as important as past accomplishments are, freedom must be defended by every generation of Americans. It falls on us, and future generations, to carry on the American experiment in self-government, and as the Constitution says, to secure the blessings of liberty to ourselves and our posterity.
          </div>
      </MobileContainer>
    ) : (
        <Body>
          <Title>The Armstrong Project</Title>
          <ImageContainer>
            <img src={AboutImg} />
          </ImageContainer>
          <Text>
            <div className="title">Mission Statement</div>
            <div className="text">
              {`The Armstrong Project is a series of new initiatives led by Wil Armstrong, to preserve the historical record of his father, Senator Bill Armstrong, and especially to build on his important work for faith, family, and freedom. Preserving history is important, especially in an era when so many Americans have forgotten our founding principles. But as important as past accomplishments are, freedom must be defended by every generation of Americans. It falls on us, and future generations, to carry on the American experiment in self-government, and as the Constitution says, "to secure the blessings of liberty to ourselves and our posterity."`}
            </div>
          </Text>
        </Body>
      )
  )
}

export default () => (
  <Layout>
    <About />
  </Layout>
);